<template>
  <div
    class="u-font-size-5 u-text-align-center u-spacing-pv-l u-spacing-ph-m u-width-100 u-height-100 u-display-flex u-flex-align-items-center u-bg-color-grey-xxx-light"
    :style="{ 'background-color': computedData.backgroundColor }"
  >
    <rb-icon
      v-if="computedData.icon"
      class="u-flex-0 rb-icon--medium u-color-grey-lighter"
      :icon="computedData.icon"
    />
    <div
      class="u-font-size-5 u-color-grey-light u-text-case-title u-white-space-normal u-text-align-left"
      :class="{ 'u-spacing-ml-s': computedData.icon }"
      :title="`${computedData.text}${computedData.textToAppend}`"
    >
      {{ computedData.text }}{{ computedData.textToAppend }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReviewStatus',
  props: {
    text: {
      type: String,
      default: ''
    },
    textToAppend: {
      type: String,
      default: ''
    },
    defaultText: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    computedData() {
      return this.text === 'pending'
        ? {
            icon: 'in-progress',
            text: this.text,
            backgroundColor: 'rgba(255, 168, 0, 0.1)',
            textToAppend: !this.textToAppend ? '' : ' - ' + this.textToAppend
          }
        : this.text === 'rejected'
        ? {
            icon: 'cross-fill-circle',
            text: this.text,
            backgroundColor: 'rgba(215, 38, 61, 0.1)',
            textToAppend: !this.textToAppend ? '' : ' - ' + this.textToAppend
          }
        : {
            icon: '',
            text: this.text === 'approved' ? this.defaultText : 'NA',
            backgroundColor: 'rgba(0,0,0,0)',
            textToAppend: !this.textToAppend ? '' : ' - ' + this.textToAppend
          };
    }
  }
};
</script>
